import {Auth} from "aws-amplify";
import { Hub } from "@aws-amplify/core";
import { DataStore, Predicates } from "@aws-amplify/datastore";
import { Action, Cask } from "../models";

export interface HubListener {
    onReady: () => void
}

export class AmplifyManager {
    private static instance: AmplifyManager;
    private syncedModels: number = 0

    private _isReady = false;

    private listeners: HubListener[] = []

    private constructor() {
        Hub.listen("datastore", async hubData => {
            const  { event } = hubData.payload;
            
            if(event === "modelSynced"){
                this.syncedModels++
            }
            //if (event === "ready") {
            if(this.syncedModels === 2 && event === "modelSynced"){
                this._isReady = true
                for(let listener of this.listeners){
                    listener.onReady()
                }
            }
          })
          DataStore.start()

    }

    public isReady(){
        return this._isReady
    }

    public static getInstance(): AmplifyManager {
        if (!AmplifyManager.instance) {
            AmplifyManager.instance = new AmplifyManager();
        }

        return AmplifyManager.instance;
    }

    public getCask = async(barcode: string) => {
    const response = await DataStore.query(Cask, c => c.barcode("eq", barcode),{
            limit: 1
          }
        )
        if(response.length === 0){
            return undefined
        }
        return response[0]
    }

    /***
     * Create a cask Datastore
     * @param lot number representing batch type (2, 3, 4...)
     * @param serial string representing barcode
     */
    public createCask = async(lot: number, serial: string) => {
        const cask = await DataStore.save(new Cask({
            barcode: serial,
            numLot: lot,
        }))
        return cask
    }

    /***
     * Update lot number
     * @param cask cask amplify object to update
     */
     public updateNumLot = async(cask: Cask, numLot: number) => {
        const newObj = Cask.copyOf(cask, updated => {
            updated.numLot = numLot
        })
        
        const res = await DataStore.save(newObj)
        console.log(res)
    }

    /***
     * Reset cask informations (collect/reconditionning dates and comments)
     * @param cask cask amplify object to reset
     */
    public resetCaskInformations = async(cask: Cask) => {
        const newObj = Cask.copyOf(cask, updated => {
            //@ts-ignore
            updated.dateCollect = null
            //@ts-ignore
            updated.dateRecept = null
            //@ts-ignore
            updated.dateRecon = null

            updated.commentCollect = ""
            updated.commentRecept = ""
            updated.commentRecon = ""

            //@ts-ignore
            updated.customField1 = null
            //@ts-ignore
            updated.customField2 = null
            //@ts-ignore
            updated.customField3 = null
        })
        
        const res = await DataStore.save(newObj)
        console.log(res)
    }

    public createCaskAction = async(barcode: string, numLot: number) => {
        const usernameResponse = await Auth.currentUserInfo()
        const now = new Date(Date.now())

        await DataStore.save(new Action({
            typeAction: "CREATE",
            barcode: barcode,
            numLot: numLot,
            dateAction: now.toISOString(),
            jourAction: now.toISOString().slice(0, 10),
            terminalID: "PORTAIL",
            username: usernameResponse.username
        }))
    }

    public createResetAction = async(cask: Cask) => {
        const usernameResponse = await Auth.currentUserInfo()
        const now = new Date(Date.now())

        await DataStore.save(new Action({
            typeAction: "RESET",
            barcode: cask.barcode,
            numLot: cask.numLot,
            dateAction: now.toISOString(),
            jourAction: now.toISOString().slice(0, 10),
            terminalID: "PORTAIL",
            username: usernameResponse.username
        }))
    }

    
    

    /***
     * Subscribe an HubListener to Hub notifications
     * It will be notify of hub notifications (onReady for example)
     * @param listener HubListener to subscribe
     */
    public subscribe(listener: HubListener) {
        if(!this.listeners.includes(listener)){
            this.listeners.push(listener)
        }
    }

    /***
     * Unsubscribe an HubListener to Hub notifications
     * @param listener HubListener to unsubscribe
     */
    public unsubscribe(listener: HubListener) {
        const index = this.listeners.indexOf(listener)
        if(index > -1){
            this.listeners.splice(index, 1)
        }
    }
}