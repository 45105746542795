/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:96110f0f-7ef0-4138-9878-99974f8fd4a3",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_j7PmuOXhS",
    "aws_user_pools_web_client_id": "7qp4hp8fr6ca2tcco7ed3n2ssu",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://xn3m6wltsvfxpddvrqyx72dxiq.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
