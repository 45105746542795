import { Component } from 'react';

import {DataStore, Predicates, SortDirection} from "aws-amplify"


import '@aws-amplify/ui/dist/style.css';
import { Action } from '../models';
import classes from './Actions.module.css';
import { AmplifyManager, HubListener } from '../helpers/AmplifyManager';
import { getFormattedDatetime } from '../helpers/DateHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';


class Actions extends Component implements HubListener{

  state = {
    actions: [],
    observable: {unsubscribe: Function},
    loading: true
  }

  async componentDidMount(){
    AmplifyManager.getInstance().subscribe(this)
    if(AmplifyManager.getInstance().isReady()){
        this.refreshActions()
    }

    this.setupListener()
  }

  componentWillUnmount(){
    AmplifyManager.getInstance().unsubscribe(this)
    
    this.state.observable.unsubscribe()
  }
  
  onReady = () => {
    this.refreshActions()
  }

  setupListener(){
      
    const observable = DataStore.observe(Action).subscribe(msg => {
        console.log(msg)
        if(msg.opType === "INSERT"){
            let actionsCopy: Action[] = [...this.state.actions]
            const newActions = [msg.element].concat(actionsCopy)
            this.setState({actions: newActions})
        }else if(msg.opType === "UPDATE"){
            const updatedObject: Action = msg.element
            let actionsCopy: Action[] = [...this.state.actions]
            const elementToUpdateIndex = actionsCopy.findIndex((it: Action) => it.id === updatedObject.id)
            if(elementToUpdateIndex !== -1){
                actionsCopy[elementToUpdateIndex] = updatedObject
                this.setState({actions: actionsCopy})
            }   
        }
    })
    this.setState({observable: observable})
  }

  refreshActions = async() => {
    try {
      const response = await DataStore.query(Action, Predicates.ALL, {
        sort: action => action.dateAction(SortDirection.DESCENDING)
      })
      console.log(response)
      this.setState({actions: response})
    }catch(error){
      console.log(error)
    }
  }

  eventActionToString = (action: string) => {
    switch(action){
        case "SCAN": return "Scan"
        case "COLLECT": return "Prise en charge pour collecte"
        case "RECEPTION": return "Réception sur AD2"
        case "RECONDITIONNING": return "Prise en charge pour reconditionnement"
        case "RESET": return "Réinitialisation"
        case "CREATE": return "Ajout au référentiel"
        default: return action
    }
  }
  
  render(){
      
    return (  
        <div style={{overflow: "auto"}} className={classes.ActionsRootContainer}>
            
            <label>Liste des événements {(this.state.actions !== undefined && this.state.actions.length > 0) ? `(${this.state.actions.length} événements)` : null}</label>
            <label className={classes.DashboardDevicesSubtitle}>Dernier événement : {this.state.actions && this.state.actions.length > 0 ? getFormattedDatetime((this.state.actions[0] as Action).dateAction) : null}</label>
            <div className={classes.DashboardDevicesTitleSeparator}/>
            <div className={classes.DashboardDevicesGroupContent}>
            
            
                <div className={classes.ActionsContainer}>
                    <table>
                        <thead>
                            <tr>
                            <th>Date</th>
                            <th>Action</th>
                            <th>Numéro de fût</th>
                            <th>Numéro de lot</th>
                            <th>Utilisateur</th>
                            <th colSpan={2}>Commentaire</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                        this.state.actions !== undefined ? this.state.actions.map((action: Action) => {
                            let ellapsedTimeSec = (new Date(Date.now()).getTime() - new Date(action.dateAction).getTime()) / 1000
                            return (
                                <tr key={action.id} style={action.numLot === 0 ? {backgroundColor: "rgb(255 118 111 / 22%)"} : ellapsedTimeSec < (60 * 60) ? {backgroundColor: "rgba(86, 255, 0, 0.08)"} : (ellapsedTimeSec < (60 * 60 * 8) ? {backgroundColor: "rgba(84, 168, 255, 0.08)"} : undefined)}>
                                    <td className={classes.MonoText}>{action.dateAction ? getFormattedDatetime(action.dateAction) : null}</td>
                                    <td>{this.eventActionToString(action.typeAction)}</td>
                                    <td className={classes.MonoText}>{action.barcode}</td>
                                    <td>{action.numLot !== 0 ? action.numLot : "Hors contrat"}</td>
                                    <td>{action.username ? action.username : null}</td>
                                    <td className={classes.CaskSpannedColumn1}>{action.compliance === true ? <FontAwesomeIcon icon={faCheckCircle} color={"green"}/> : action.compliance === false ? <FontAwesomeIcon icon={faExclamationTriangle} color={"orange"}/> : null}</td>
                                    <td className={classes.CaskSpannedColumn2}>{action.comment}</td>
                                </tr>
                            
                            )
                        }) : null
                        }    
                        </tbody>
                    </table>    
                </div>                
            </div>
        </div>
    );
  }
}

export default Actions;
